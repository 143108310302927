import { Box, Button } from '@mui/material'
import React from 'react'
import { AppLogo } from 'src/components/atoms/appLogo'
import NavBar from 'src/components/organisms/layouts/app/NavBar'

export default function Header() {
  return (
    <header
      style={{
        backgroundColor: '#BF7373',
        height: '207px',
        position: 'relative'
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0 32px',
          paddingTop: '32px',
          height: '105px'
        }}>
        <AppLogo />
        <Button
          sx={{
            backgroundColor: '#A2A19D',
            color: '#FFF',
            minWidth: '150px',
            height: '55px',
            fontWeight: 900,
            fontSize: 18
          }}>
          S'inscrire
        </Button>
      </Box>
      <NavBar />
    </header>
  )
}
