import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SearchIcon from '@mui/icons-material/Search'
import {
Box,
Button,
Card,
CardContent,
Checkbox,
Container,
FormControlLabel,
FormGroup,
Grid,
MenuItem,
Rating,
Select,
TextField,
Typography
} from '@mui/material'
import React,{ ReactNode,useState } from 'react'
import FrontLayout from 'src/components/organisms/layouts/app/FrontLayout'

export function ProfessorList() {
  const [openLocationFilterBox, toggleOpenLocationFilterBox] = useState<boolean>(false)
  const [openSubjectFilterBox, toggleOpenSubjectFilterBox] = useState<boolean>(false)
  const [openLevelFilterBox, toggleOpenLevelFilterBox] = useState<boolean>(false)

  return (
    <FrontLayout>
      <Box
        sx={{
          height: '254px',
          backgroundColor: '#BEBEBE'
        }}>
        <Container
          sx={{
            maxWidth: '1200px'
          }}>
          <Typography
            sx={{
              fontWeight: 900,
              color: '#FFF',
              textShadow: '1px 1px 1px gray',
              paddingTop: '16px'
            }}
            variant="h3">
            CHERCHE TON PROF
          </Typography>
          <Box
            sx={{
              marginTop: '16px'
            }}>
            <TextField
              fullWidth
              sx={{
                height: '50px',
                maxWidth: '692px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '16px',
                  boxShadow: '0px 8px 8px -8px gray'
                },
                '& input': {
                  backgroundColor: '#FFF',
                  color: '#BEBEBE',
                  borderRadius: '16px'
                }
              }}
            />
          </Box>
          <Box
            sx={{
              marginTop: '32px',
              display: 'flex',
              columnGap: '12px',
              position: 'relative'
            }}>
            <Button
              sx={{
                borderRadius: 1,
                color: 'neutral.300',
                justifyContent: 'space-between',
                px: 3,
                textAlign: 'left',
                textTransform: 'none',
                backgroundColor: '#767777',
                minWidth: '180px',
                fontWeight: 900,
                fontSize: 18,
                '& .MuiButton-startIcon': {
                  color: 'neutral.400'
                },
                '&:hover': {
                  backgroundColor: 'rgba(118, 119, 119, .8)'
                }
              }}
              onClick={() => {
                toggleOpenLocationFilterBox(!openLocationFilterBox)
                toggleOpenSubjectFilterBox(false)
                toggleOpenLevelFilterBox(false)
              }}
              endIcon={openLocationFilterBox ? <ArrowDropUpIcon/> : <ArrowDropDownIcon />}>
              Ma zone
            </Button>
            <Button
              sx={{
                borderRadius: 1,
                color: 'neutral.300',
                justifyContent: 'space-between',
                px: 3,
                textAlign: 'left',
                textTransform: 'none',
                backgroundColor: '#767777',
                minWidth: '180px',
                fontWeight: 900,
                fontSize: 18,
                '& .MuiButton-startIcon': {
                  color: 'neutral.400'
                },
                '&:hover': {
                  backgroundColor: 'rgba(118, 119, 119, .8)'
                }
              }}
              onClick={() => {
                toggleOpenSubjectFilterBox(!openSubjectFilterBox)
                toggleOpenLocationFilterBox(false)
                toggleOpenLevelFilterBox(false)
              }}
              endIcon={openSubjectFilterBox ? <ArrowDropUpIcon/> : <ArrowDropDownIcon />}>
              Matière
            </Button>
            <Button
              sx={{
                borderRadius: 1,
                color: 'neutral.300',
                justifyContent: 'space-between',
                px: 3,
                textAlign: 'left',
                textTransform: 'none',
                backgroundColor: '#767777',
                minWidth: '180px',
                fontWeight: 900,
                fontSize: 18,
                '& .MuiButton-startIcon': {
                  color: 'neutral.400'
                },
                '&:hover': {
                  backgroundColor: 'rgba(118, 119, 119, .8)'
                }
              }}
              onClick={() => {
                toggleOpenLocationFilterBox(false)
                toggleOpenSubjectFilterBox(false)
                toggleOpenLevelFilterBox(!openLevelFilterBox)
              }}
              endIcon={openLevelFilterBox ? <ArrowDropUpIcon/> : <ArrowDropDownIcon />}>
              Niveau
            </Button>
            <FilterModal title="Ma zone" show={openLocationFilterBox} left="0">
              <Box
                sx={{
                  marginTop: '16px'
                }}>
                <FormGroup
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexWrap: 'no-wrap',
                    flexDirection: 'row',
                    position: 'relative'
                  }}>
                  <TextField
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      sx: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        border: 'solid 1px #767777'
                      }
                    }}
                    sx={{
                      maxWidth: '317.5px',
                      width: '100%'
                    }}
                  />
                  <Select
                    defaultValue="0"
                    sx={{
                      maxWidth: '100px',
                      width: '100%',
                      position: 'absolute',
                      right: 0,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      backgroundColor: '#FFF',
                      zIndex: 10,
                      color: '#767777',
                      fontWeight: 900,
                      border: 'solid 1px #767777'
                    }}>
                    <MenuItem selected value={0}>
                      0 Km
                    </MenuItem>
                    <MenuItem value={10}>10 Km</MenuItem>
                    <MenuItem value={20}>20 Km</MenuItem>
                    <MenuItem value={30}>30 Km</MenuItem>
                  </Select>
                </FormGroup>
              </Box>
              <Box
                sx={{
                  marginTop: '16px'
                }}>
                <Button
                  sx={{
                    backgroundColor: '#402726',
                    padding: '16px',
                    '&:hover': {
                      backgroundColor: 'rgba(64, 39, 38, .5)'
                    }
                  }}
                  fullWidth>
                  <Typography color="#fff">Appliquer</Typography>
                </Button>
                <Button
                  sx={{
                    marginTop: '16px',
                    backgroundColor: '#767777',
                    padding: '16px',
                    '&:hover': {
                      backgroundColor: 'rgba(64, 39, 38, .5)'
                    }
                  }}
                  fullWidth>
                  <Typography color="#fff">Réinitialiser</Typography>
                </Button>
              </Box>
            </FilterModal>

            <FilterModal title="Matière" left="94px" show={openSubjectFilterBox}>
              <Box
                sx={{
                  marginTop: '16px'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    columnGap: '16px',
                    paddingBottom: '16px',
                    borderBottom: 'solid 1px #767777'
                  }}>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox />}
                    label="Sélectionner tout"
                    labelPlacement="end"
                  />
                  <Typography color="#767777">Réinitialiser</Typography>
                  <Box
                    sx={{
                      borderLeft: 'solid 1px #767777',
                      marginLeft: '8px',
                      width: '1px',
                      height: '20px'
                    }}
                  />
                  <SearchIcon
                    sx={{
                      color: '#767777',
                      marginTop: '8px'
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  maxHeight: '450px',
                  height: '100%',
                  overflowY: 'scroll',
                  overflowX: 'hidden'
                }}>
                {Array.from(Array(30).keys()).map((value) => {
                  return (
                    <FormControlLabel
                      sx={{
                        width: '100%'
                      }}
                      value="end"
                      control={<Checkbox />}
                      label={`Matière ${value + 1}`}
                      labelPlacement="end"
                    />
                  )
                })}
              </Box>
              <Box
                sx={{
                  marginTop: '16px'
                }}>
                <Button
                  sx={{
                    marginTop: '16px',
                    backgroundColor: '#767777',
                    padding: '16px',
                    '&:hover': {
                      backgroundColor: 'rgba(64, 39, 38, .5)'
                    }
                  }}
                  fullWidth>
                  <Typography color="#fff">Fermer</Typography>
                </Button>
              </Box>
            </FilterModal>

            <FilterModal title="Niveau" left="calc(194px * 2)" show={openLevelFilterBox}>
              <Box
                sx={{
                  marginTop: '16px'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    columnGap: '16px',
                    paddingBottom: '16px',
                    borderBottom: 'solid 1px #767777'
                  }}>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox />}
                    label="Sélectionner tout"
                    labelPlacement="end"
                  />
                  <Typography color="#767777">Réinitialiser</Typography>
                  <Box
                    sx={{
                      borderLeft: 'solid 1px #767777',
                      marginLeft: '8px',
                      width: '1px',
                      height: '20px'
                    }}
                  />
                  <SearchIcon
                    sx={{
                      color: '#767777',
                      marginTop: '8px'
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  maxHeight: '450px',
                  height: '100%',
                  overflowY: 'scroll',
                  overflowX: 'hidden'
                }}>
                {Array.from(Array(30).keys()).map((value) => {
                  return (
                    <FormControlLabel
                      sx={{
                        width: '100%'
                      }}
                      value="end"
                      control={<Checkbox />}
                      label={`Niveau ${value + 1}`}
                      labelPlacement="end"
                    />
                  )
                })}
              </Box>
              <Box
                sx={{
                  marginTop: '16px'
                }}>
                <Button
                  sx={{
                    marginTop: '16px',
                    backgroundColor: '#767777',
                    padding: '16px',
                    '&:hover': {
                      backgroundColor: 'rgba(64, 39, 38, .5)'
                    }
                  }}
                  fullWidth>
                  <Typography color="#fff">Fermer</Typography>
                </Button>
              </Box>
            </FilterModal>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          height: 'auto',
          backgroundColor: '#A2A19D',
          paddingBottom: '64px'
        }}>
        <Container
          sx={{
            maxWidth: '1200px'
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 900,
                  color: '#FFF',
                  textShadow: '1px 1px 1px gray',
                  paddingTop: '16px'
                }}
                variant="h4">
                Actuellement 200 profs
              </Typography>
            </Grid>
            {Array.from(Array(12).keys()).map(() => (
              <Grid item xs={6}>
                <Card
                  elevation={12}
                  sx={{
                    borderRadius: '12px',
                    marginTop: '16px'
                  }}>
                  <CardContent
                    sx={{
                      position: 'relative'
                    }}>
                    <Box
                      sx={{
                        height: '30px',
                        width: '30px',
                        borderRadius: '100%',
                        backgroundColor: '#10AA10',
                        position: 'absolute',
                        top: '16px',
                        right: '24px'
                      }}
                    />
                    <Typography
                      sx={{
                        marginBottom: '16px'
                      }}
                      variant="h4">
                      John Smith
                    </Typography>
                    <Box>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse id iusto nihil
                      quaerat quibusdam? Accusamus amet at consequatur deleniti, doloremque ea enim
                      eos ipsa magni nemo nisi numquam, quas quisquam.
                    </Box>
                    <Box
                      sx={{
                        marginBottom: '16px',
                        marginTop: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                      <Typography variant="h6">Lausane</Typography>
                      <Rating
                        sx={{
                          color: '#575657'
                        }}
                        value={4.5}
                        precision={0.5}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Box
                sx={{
                  marginTop: '32px',
                  display: 'flex',
                  columnGap: '12px',
                  justifyContent: 'flex-end'
                }}>
                <Button
                  sx={{
                    borderRadius: 1,
                    color: 'neutral.300',
                    justifyContent: 'flex-start',
                    px: 3,
                    textAlign: 'left',
                    textTransform: 'none',
                    backgroundColor: '#767777',
                    minWidth: '30px',
                    fontWeight: 900,
                    fontSize: 18,
                    '& .MuiButton-startIcon': {
                      color: 'neutral.400'
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255, 0.08)'
                    }
                  }}>
                  1
                </Button>
                <Button
                  sx={{
                    borderRadius: 1,
                    color: 'neutral.300',
                    justifyContent: 'flex-start',
                    px: 3,
                    textAlign: 'left',
                    textTransform: 'none',
                    backgroundColor: '#767777',
                    minWidth: '30px',
                    fontWeight: 900,
                    fontSize: 18,
                    '& .MuiButton-startIcon': {
                      color: 'neutral.400'
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255, 0.08)'
                    }
                  }}>
                  2
                </Button>
                <Button
                  sx={{
                    borderRadius: 1,
                    color: 'neutral.300',
                    justifyContent: 'flex-start',
                    px: 3,
                    textAlign: 'left',
                    textTransform: 'none',
                    backgroundColor: '#767777',
                    minWidth: '30px',
                    fontWeight: 900,
                    fontSize: 18,
                    '& .MuiButton-startIcon': {
                      color: 'neutral.400'
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255, 0.08)'
                    }
                  }}>
                  3
                </Button>
                <Button
                  sx={{
                    borderRadius: 1,
                    color: 'neutral.300',
                    justifyContent: 'flex-start',
                    px: 3,
                    textAlign: 'left',
                    textTransform: 'none',
                    backgroundColor: '#767777',
                    minWidth: '30px',
                    fontWeight: 900,
                    fontSize: 18,
                    '& .MuiButton-startIcon': {
                      color: 'neutral.400'
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255, 0.08)'
                    }
                  }}>
                  ...
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </FrontLayout>
  )
}

type FilterModalProps = {
  show: boolean
  left: string
  title: string
  children: ReactNode
}

export function FilterModal({ show, left, title, children }: FilterModalProps) {
  return (
    <Box
      sx={{
        position: 'absolute',
        backgroundColor: '#fff',
        minHeight: '200px',
        maxWidth: '450px',
        width: '100%',
        borderRadius: '16px',
        zIndex: 10,
        top: '54px',
        padding: '16px',
        boxShadow: '0px 8px 8px -8px gray',
        border: 'solid 1px #767777',
        left: left,
        display: show ? 'block' : 'none'
      }}>
      <Typography
        sx={{
          fontWeight: 'lighter'
        }}
        color="#767777"
        variant={'h6'}>
        {title}
      </Typography>
      {children}
    </Box>
  )
}
