import {Box, Button, List, ListItem} from '@mui/material'
import React from 'react'
import {Link} from "gatsby-material-ui-components";

export default function NavBar() {
  return (
    <nav style={{
      position: 'absolute',
      bottom: 0
    }} aria-label="main mailbox folders">
      <List sx={{
        paddingBottom: 0
      }}>
        <ListItem sx={{
          paddingBottom: 0
        }}>
          <Link
            sx={{
              marginRight: '16px'
            }}
            to="/app/search">
            <Button
              disableRipple
              sx={{
                borderRadius: 0,
                color: 'neutral.300',
                justifyContent: 'flex-start',
                px: 3,
                textAlign: 'left',
                textTransform: 'none',
                width: '100%',
                backgroundColor: '#673C3D',
                '& .MuiButton-startIcon': {
                  color: 'neutral.400'
                },
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255, 0.08)'
                }
              }}>
              <Box sx={{ flexGrow: 1 }}>Recherche</Box>
            </Button>
          </Link>
          <Link
            sx={{
              marginRight: '16px',
            }}
            to="/app/about">
            <Button
              disableRipple
              sx={{
                borderRadius: 0,
                color: 'neutral.300',
                justifyContent: 'flex-start',
                px: 3,
                textAlign: 'left',
                textTransform: 'none',
                backgroundColor: '#673C3D',
                width: '100%',
                '& .MuiButton-startIcon': {
                  color: 'neutral.400'
                },
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255, 0.08)'
                }
              }}>
              <Box sx={{ flexGrow: 1 }}>A propos</Box>
            </Button>
          </Link>
          <Link
            sx={{
              marginRight: '16px'
            }}
            to="/app/news">
            <Button
              disableRipple
              sx={{
                borderRadius: 0,
                color: 'neutral.300',
                justifyContent: 'flex-start',
                px: 3,
                textAlign: 'left',
                textTransform: 'none',
                backgroundColor: '#673C3D',
                width: '100%',
                '& .MuiButton-startIcon': {
                  color: 'neutral.400'
                },
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255, 0.08)'
                }
              }}>
              <Box sx={{ flexGrow: 1 }}>News</Box>
            </Button>
          </Link>
        </ListItem>
      </List>
    </nav>
  )
}
