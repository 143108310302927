import Header from "src/components/organisms/layouts/app/Header";
import React from "react";
import {Container} from "@mui/material";

export default function FrontLayout({children} : any) {
    return(
      <>
        <Container sx={{maxWidth: '1200px!important', paddingTop: 0}}>
          <Header/>
          {children}
        </Container>
      </>
    )
}
