import React from "react";
import {Box} from "@mui/material";

export function AppLogo(){
  return(
    <Box sx={{
      height: '92px',
      width: '92px',
      borderRadius: '100%',
      backgroundColor: '#402726'
    }}/>
  )
}
